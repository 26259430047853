import React, { useEffect } from 'react' 
import { getAuthAud, isLoggedIn } from '../services/auth'
import { useQuery } from 'react-apollo'
import { useExperiment } from '../hooks'
import gql from 'graphql-tag'

const GET_USER_INFO = gql`{
  me {
    photo {
      url
    }
  }
}`

const ABC_EXPERIMENT_ID = "67QGvS_FQxOFB9aFrg_wkA"

function PublicPage() {
  const { loading, data } = useQuery(GET_USER_INFO)

  const variant = useExperiment(ABC_EXPERIMENT_ID)

  useEffect(() => {
    if (!loading) {
      const aud = getAuthAud()
      const accessToken = window.localStorage.getItem("auth:defaultToken")
      const loggedIn = isLoggedIn()
      const profilePicUrl = (data && data.me && data.me.photo && data.me.photo.url) || null
      const abVariants = { [ABC_EXPERIMENT_ID]: variant }

      // TODO: Change * to allowed postMessage domains for security
      window.parent.postMessage({ source: "R4", payload: { aud, accessToken, isLoggedIn: loggedIn, profilePicUrl, abVariants } }, '*')
    }
  }, [loading, variant])

  return <div />
}

export default PublicPage